.spinner {

    &:before {
        content: ' ';
        display: block;
        width: 64px;
        height: 64px;
        background-image: url(../../img/ajax-loader.gif);
        position: absolute;
        top:50%;
        left:50%;
        margin-top: -32px;
        margin-left: -32px;
        opacity: 0;
    }
    &.is-loading:before {
        opacity: 1;
        transition:opacity 0.2s;
    }

}

.is-loading {
    opacity: 0.5;
}

#slider.spinner:before {
     z-index: 11;
}
