/* ICOMOON */
@font-face {
    font-family: 'icomoon';
    src:url('../../fonts/icomoon/icomoon.eot?-hg3dn1');
    src:url('../../fonts/icomoon/icomoon.eot?#iefix-hg3dn1') format('embedded-opentype'),
        url('../../fonts/icomoon/icomoon.woff?-hg3dn1') format('woff'),
        url('../../fonts/icomoon/icomoon.ttf?-hg3dn1') format('truetype'),
        url('../../fonts/icomoon/icomoon.svg?-hg3dn1#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    border:none;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.is-active {
        color: #a1c500;
    }
    &:hover {
        color: #a1c500;
        border:none;
    }

}

.icon-ico-filter:before {
    content: "\e618";
}

.icon-ico-illustration:before {
    content: "\e600";
}

.icon-ico-arrow-left:before {
    content: "\e601";
}

.icon-ico-arrow-right:before {
    content: "\e602";
}

.icon-ico-check:before {
    content: "\e603";
}

.icon-ico-deer:before {
    content: "\e604";
}

.icon-ico-fotos:before {
    content: "\e605";
}

.icon-ico-grid:before {
    content: "\e606";
}

.icon-ico-linkedin:before {
    content: "\e607";
}

.icon-ico-list:before {
    content: "\e608";
}

.icon-ico-logo:before {
    content: "\e609";
}

.icon-ico-mail:before {
    content: "\e60a";
}

.icon-ico-marker:before {
    content: "\e60b";
}

.icon-ico-menu:before {
    content: "\e60c";
}

.icon-ico-menu2:before {
    content: "\e60d";
}

.icon-ico-phone:before {
    content: "\e60e";
}

.icon-ico-phone2:before {
    content: "\e60f";
}

.icon-ico-pinterest:before {
    content: "\e610";
}

.icon-ico-play:before {
    content: "\e611";
}

.icon-ico-rocket:before {
    content: "\e612";
}


.icon-ico-square:before {
    content: "\e613";
}

.is-checked > .icon-ico-square:before {
    content: "\e603";
      color: #fff;
}

.icon-ico-star:before {
    content: "\e614";
}

.icon-ico-textcloud:before {
    content: "\e615";
}

.icon-ico-textcloud2:before {
    content: "\e616";
}

.icon-ico-twitter:before {
    content: "\e617";
}

.icon-ico-home:before {
  content: "\e619";
}

.is-reversed {
    transform: rotate(180deg);
}

.menu-icon{
    display: inline-block;
    position: relative;
    top: 0;
    right: 0;
    height: 52px;
    width: 52px;
    color: #9f9f9f;
    line-height: 52px;
    font-size: 40px;
    transition: color 0.25s ease;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

}
.menu-icon:hover {
    color: #9f9f9f;
}


.icon.is-active span {
    color: #a1c500;
}

.icon-ico-info:before {
    content: "\e61a";
}

.icon-ico-timeline:before {
    content: "\e61b";
}
