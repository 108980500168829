.about {
    display: none;
    padding:0 80px;
    &.is-open {
        -webkit-animation: fadein 1s;
        -moz-animation: fadein 1s;
        -ms-animation: fadein 1s;
        -o-animation: fadein 1s;
        animation: fadein 1s;
        display: block;
    }
    @media screen and (max-width: 992px) {
        padding:  0 20px;
    }
}
