
// states
.is-nav-open {

    .column--nav {
        margin-right: 0%;
    }

    .sidebar {
        right:25%;
    }

     #slider {
        width: 75%;
    }

}

 @media screen and (max-width: 992px) {
    .is-contact-open {
        .column--nav {
           top:0;
        }
    }
}

.is-right-open {

    .column--right {
        margin-right: 0;
    }

    .column--nav {
        right: 50%;
    }

    .sidebar {
        right:50%;
    }

}

.is-nav-open.is-right-open {

    .column--right {
        margin-right: 0;
    }

    .sidebar {
        right:75%;
    }
}

 @media screen and (max-width: 992px) {

    .column--nav {
        display: none;
    }

    .column--right {
        width: 100%;
        margin-right: -100%;
        position: absolute;
        height: 100%;
        overflow-y:scroll;
    }

    .is-nav-open {

        .column--left {
            height: 100%;
        }

        .column--nav {
            display: block;
        }

        #slider {
            width: 100%;
        }

        .sidebar {
            right:auto;
        }
    }

    .is-right-open {

        .sidebar {
            right:auto;
        }

        .column--right {
            position: absolute;
            margin-right: 0;
            height: 100%;
            overflow-y:scroll;
        }

    }

   .is-nav-open.is-right-open {

        .sidebar {
            right:auto;
        }
    }

 }
