.column--right {
        position: relative;
    float: left;
    transition: all 0.5s ease;
    height: 100%;
    overflow: hidden;
    width: 50%;
    height: 100%;
    margin-right: -50%;
    background:#fff;
    overflow: auto;
    position: absolute;
    right: 0;
    z-index: 11;
}

.column--right h1 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 32px;
}

.column--right p {
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 24px;
    font-family: 'Karma', serif;
}

.column--right blockquote {
    font-size: 32px;
    line-height: 32px;
    font-weight: 300;
    padding: 0 48px;
    margin: 40px 0;
    border-left: 12px solid #f1f1f1;
}

.column--right img {
    margin: 24px 0;
    max-width: 100%;
    border: 24px solid #f6f6f6;
}
