.contact {
    padding: 0 24px;
    display: none;
    color:#c9c9c9;
    &.is-open {
        display: block;
    }
    @media screen and (max-width: 992px) {
        &.is-open {
            position: fixed;
            display: block;
            top:0;
            left:0;
            bottom:52px;
            right:0;
            z-index: 9;
            background:#0D171B;
            overflow: scroll;

        }
    }

}
.contact-scroller {
    @media screen and (max-width: 992px) {
        margin-bottom:72px;
        margin-top:20px;
    }
}

.response {
    text-align: left;
    background:transparent;
    display: none;
    width: 100%;
    color:#a1c500;
    padding:12px 0px;
    font-weight: 400;
    outline: none;
    &.is-open {
        display: block;
        animation: fadein 1s;
    }
}

input, textarea  {
    width: 100%;
    outline: none;
    background: none repeat scroll 0 0 #1F2122;
    border: 1px solid #1f2122;
    color: #9f9f9f;
    display: block;
    font-weight: 300;
    margin: 0 0 16px;
    outline: medium none;
    padding: 6px 12px;
    border-radius: 4px;
    transition: all 0.25s ease;
}

input[type="submit"] {
    display: inline-block;
    width: auto;
    margin:0;
    float: right;
    &:hover {
        background:#9F9F9F;
        color:#1f2122;
        transition: all 0.3s;
    }
}

input:-webkit-autofill {
    color: #c9c9c9 !important;
    background-color: #fff !important;
}

