.sidebar {
	position: absolute;
	top: 0;
	right: 0;
	width: 52px;
	height: 100%;
	background-color: rgba(0,0,0,0.5);
	z-index: 10;
	transition: all 0.5s;
	@media screen and (max-width: 992px) {
		position: fixed;
		bottom: 0;
		width: 100%;
		height: 52px;
		top:auto;
		z-index: 99;
		text-align: center;
	}
}

.sidebar-icon{
	display: inline-block;
	position: relative;
	top: 0;
	right: 0;
	height: 52px;
	width: 52px;
	color: #9f9f9f;
	line-height: 52px;
	font-size: 40px;
	transition: color 0.25s ease;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
}

.sidebar-icon:hover{
	color:#a1c500;
}

.do-open-navigation{
	@media screen and (max-width: 992px) {
		display: none;
	}
}
