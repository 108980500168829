.tile{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	overflow: hidden;
}

.tile a{
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.5);
	transition: all 0.25s ease;

}

.tile a:hover,
.tile a.active{
	background-color: rgba(0,0,0,0);
}

.tile h3{
	position: absolute;
	top: 50%;
	left: 0;
	display: block;
	width: 100%;
	height: 78px;
	margin: -39px 0 0 0;
	padding: 0 12px 0 12%;
	font-weight: 300;
	text-transform: uppercase;
	text-overflow: ellipsis;
	overflow: hidden;
	color: rgba(255,255,255,0.75);
	line-height: 78px;
	text-align: left;
	background-color: rgba(0,0,0,0.25);
	z-index: 1;
	font-size: 24px;
	@media screen and (max-width: 1440px) {
		font-size: 1.6vw;
	}
	@media screen and (max-width: 992px) {
		background:transparent;
		font-size: 1.2em;
		text-align: center;
	}
}
