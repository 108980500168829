.logo-container{
    background: rgb(67,84,0); /* Old browsers */
    background: linear-gradient(135deg,  rgba(67,84,0,1) 0%,rgba(61,47,11,1) 100%);
}

#logo{
    width: 400px;
    height: 400px;
    background-color: transparent;
    background-image: url('../../img/logo-anne-reitsema-fotografie.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    position: fixed;
    top: 0;
    left: 0;
    transition: opacity 0.5s;

    &.is-fadeout {
            opacity: 0.2;
    }
    @media screen and (max-width: 768px) {
        width: 50%;
        height: 25%;
    }
}


