#home {
    width: 100%;
    height: 100%;
    position: absolute;

    left:-100%;
    animation:fadeout 1s;
    &.is-open {
       left:0;
    }
}
