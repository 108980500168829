html, body {
	height: 100%;
}

.screenheight100 {
	height: 100%;

	@media screen and (max-width: 992px) {
		height: 25%;
	}

}

