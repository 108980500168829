.column-0{
	width: 0%;
}
.column-25{
	width: 25%;
}
.column-50{
	width: 50%;
}
.column-75{
	width: 75%;
}
.column-100{
	width: 100%;
}

@media (max-width: 992px) {
	.column-0{
		width: 0%;
	}
	.column-25{
		width: 100%;
	}
	.column-50{
		width: 100%;
	}
	.column-75{
		width: 100%;
	}
	.column{
		width: 100%;
	}
}
