.navigation {
    display: none;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li a {
            position: relative;
            display: block;
            padding: 0 22px;
            font-weight: 400;
            font-size: 16px;
            line-height: 52px;
            height: 52px;
            text-transform: uppercase;
            text-decoration: none;
            color: #9f9f9f;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
                position: absolute;
                top: 0;
                right: 0;
                display: block;
                width: 52px;
                height: 52px;
                line-height: 52px;
                font-size: 40px;
                text-decoration: none;
                text-align: center;
            }
        }
    }

    // states
    &.is-open {
        display: block;
    }
}


// states
.navigation ul li a:hover,
.navigation ul li.active a{
    color:#a1c500;
}

.navigation ul li a.alt span{
    left: 0;
}

