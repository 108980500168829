.column--nav {
    position: relative;
    float: left;
    transition: all 0.5s ease;
    height: 100%;
    overflow: hidden;
    background-color: #0A0D0D;
    width: 25%;
    margin-right:-25%;
    overflow-y:auto;
    position: absolute;
    right:0;
    z-index: 11;
    @media screen and (max-width: 992px) {
        position: absolute;
        width: 100%;
        height: auto;
        bottom:52px;
        top:0;
        display: none;
    }
}

.column--nav__header {
    text-align: left;
    height: 52px;
    margin: 0;
    font-weight: 400;
    text-transform: uppercase;
    color: rgba(255,255,255,0.25);
    line-height: 52px;
    @media screen and (max-width: 992px) {
        display: none;
    }
}


