html{
	height: 100%;
}

body{
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 16px;
	font-weight: 400;
	background-color: #1F2122;

	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 100%;
}


.wrapper {
	height: 100%;
	width: 100%;
	position: relative;
	overflow: hidden;
	@media screen and (max-width: 768px) {
		height: 100%;
		overflow-y:scroll;
	}
}

a {
	text-decoration: none !important;
}

.clickable {
	cursor: pointer;
}




