.photolist {
    display: none;
    &.is-open {
        display: block;
    }
    @media screen and (max-width: 768px) {
        overflow-x:scroll;
        position: absolute;
        bottom:0px;
        width: 100%;

    }

}

#photolist {
    @media screen and (max-width: 768px) {
        width: 300000px;
    }

}

.photolist-header{
    position: relative;
    width: 100%;
    height: 52px;
    padding: 0 5px;
    /*background-color: #ff6600;*/
    overflow: hidden;
}

.photolist-header-filter {
    @extend .photolist-header;
    background:#1F2122;
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.photolist-header-filter a.photolist-header-filter-toggle {
    float: right;
}

.photolist-header p{
    display: inline-block;
    margin-left: 12px;
    height: 52px;
    padding: 0;
    color: rgba(255,255,255,0.5);
    text-transform: uppercase;
    line-height: 52px;
    float: left;
    overflow: hidden;
}

.photolist-header a{
    display: inline-block;
    width: 36px;
    height: 52px;
    padding: 0;
    float: left;
    &.toggle-filters {
        float:right;
    }
    &.icon {
        color:transparent;
    }
}

.filter-counter {
    position: absolute;
    top: 12px;
    right: 0px;
    width: 14px;
    height: 14px;
    font-size: 8px;
    font-weight: 400;
    color: black;
    text-align: center;
    line-height: 14px;
    background-color: #454545;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    /*border: 1px solid #5a6f00;*/
    -moz-box-shadow: 0px 1px 2px #4e4c45;
    -webkit-box-shadow: 0px 1px 2px #4e4c45;
    box-shadow: 0px 1px 2px #4e4c45;
    &.is-active {
        background-color: #fff;
    }

}

.filter-preview {
    white-space: nowrap;
    text-overflow: ellipsis;
}

.toggle-filter {
    position: relative;
}

.toggle-favorites {
    position: relative;
}

.favorites-counter {
    position: absolute;
    top: 12px;
    right: 0px;
    width: 14px;
    height: 14px;
    font-size: 8px;
    font-weight: 400;
    color: black;
    text-align: center;
    line-height: 14px;
    background-color: #454545;
    border-radius: 7px;
    box-shadow: 0px 1px 2px #4e4c45;
    &.is-active {
        background-color: #fff;
    }
}

.photolist-header a span{
    display: block;
    width: 36px;
    height: 52px;
    color: #454545;
    line-height: 52px;
    font-size: 40px;
    text-decoration: none;
    text-align: center;
}


.photolist-no-image {
    padding:24px;
    color:#9f9f9f;
}

.imagecell a{
    position: absolute;
    top:0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    color: rgba(255,255,255,0.5);
    padding: 0 24px 0 64px;
    line-height: 68px;
    background-color: rgba(0,0,0,0.5);
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.25s ease;
    z-index: 3;
}

.is-grid-view .imagecell a{
    color: transparent;

}

.imagecell a:hover,
.imagecell a.active{
    background-color: rgba(0,0,0,0);
}



.is-list-view .imagecell a:hover,
.is-list-view .imagecell a.active{
    color: rgba(255,255,255,0.75);
}

.imagecell-fav {
    position: absolute;
    bottom: 0;
    right: 0;
    color: rgba(255,255,255,0.25);
    transition: color 0.25s ease;
    width: 48px;
    height: 52px;
    line-height: 52px;
    font-size: 24px;
    z-index: 4;
}

.imagecell-fav:hover{
    color: rgba(255,255,255,1);
}

.is-fav > .imagecell-fav {
    color:#a1c500;
}


.imagecell {
    &.is-hidden {
        display: none;
    }
}


// grid and list states
.is-grid-view .imagecell{
    width: 33.3%;
    height: 100px;
    float: left;
    position: relative;
    overflow: hidden;
    @media screen and (max-width: 768px) {
        width: 100px;
        height: 100px;
    }
}

.is-list-view .imagecell{
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 8px 0;
    border-bottom: 1px solid #1c1c1c;
}

.is-grid-view .image{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.is-list-view .image{
    width: 52px;
    height: 52px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}


