.column--left {
    position: relative;
    float: left;
    transition: all 0.5s ease;
    height: 100%;
    overflow: hidden;
    width: 100%;
    .row {
        height: 100%;
    }
}
