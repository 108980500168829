#slider {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: -1;
    &.is-open {
        z-index: 1;
    }

    @media screen and (max-width: 992px) {
        position: absolute;
        top:0;
        bottom: 152px;
        left:0;
        right: 0;
        height: auto;
        &.is-open {
        z-index: 99;
    }
    }
}

.photo-nav{
    position: absolute;
    top: 0;
    width: 25%;
    z-index:11;
    height: 100%;


    &.prev {
        left: 0;
        > span:before {
            top: 50%;
            position: absolute;
            left: 0;
            margin-top: -26px;
        }
    }
    &.next {

        right: 52px;
         > span:before {
            top: 50%;
            position: absolute;
            right: 0;
            margin-top: -26px;
        }
        @media screen and (max-width: 992px) {
            right: 0;
        }
    }
    span {
        height: 100%;
        width: 100%;
        font-size: 80px;
        @media screen and (max-width: 992px) {
            font-size: 50px;
        }
        &:hover {
            color:#a1c500;
        }
        &:before {

        }
    }
}

.k-note-pulse {
    text-align: center;
    padding:0 52px;
}

