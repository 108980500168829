
.timeline {
    display: none;
    padding:80px;
    background:#fff;
    position:relative;

    &.is-open {
       -webkit-animation: fadein 1s;
        -moz-animation: fadein 1s;
        -ms-animation: fadein 1s;
        -o-animation: fadein 1s;
        animation: fadein 1s;
        display: block;
    }

    @media screen and (max-width: 992px) {
        padding: 20px;
    }
}



.line{
    padding: 18px 0;
}

.line p {
    display: block;
    padding-right: 16px;
    color: #454545;
    font-size: 14px;
    line-height: 120%;
    background-color: white;
}

.timeline-item{
    position: relative;
    border-left: 1px solid #bababa;
    padding: 18px 0;

    img {
        max-height: 360px;
    }

    .k-video {
        margin: 0 24px 12px 36px;
        border: 12px solid #f6f6f6;
    }
}
.timeline-item:after{
    content:'';
    display: block;
    z-index: 200;
    height: 13px;
    width: 13px;
    position: absolute;
    top: 24px;
    left: -7px;
    border: 1px solid #bababa;
    background-color: white;
    border-radius: 6px;
}

/* TIMELINE TEXT */
.timeline-text h2 {
    margin: 0 24px 12px 36px;
}
.timeline-text h3 {
    margin: 0 24px 12px 36px;
    font-size: 18px;
    text-transform: uppercase;
}
.timeline-text p {
    margin: 0 24px 12px 36px;
}
.timeline-text img {
    margin: 0 24px 12px 36px;
    border: 12px solid #f6f6f6;
}

/* TIMELINE TWITTER */
.timeline-tweet {
    position: relative;
    font-size: 16px;
    padding-left: 48px;
    padding: 0 48px;
    margin: 40px 0;
    margin-left: 76px;
    border-left: 12px solid #f1f1f1;

    p {
        margin: 0px;
        padding: 0px;
    }

    img {
        border: 0;
        margin:12px 0;
    }

    &:before {
        font-family: 'icomoon';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e617";
        color: #55acee;
        position: absolute;
        top: -46px;
        left: -76px;
        font-size: 40px;
    }

}



/* TIMELINE BLOCK */
.timeline-block{
    padding: 18px 0;
    margin: 12px 36px 24px;
    background-color: #f1f1f1;
}
.timeline-block h2 {
    font-size:1.6em;
    margin: 0 18px 8px;
}
.timeline-block p{
    font-size: 14px;
    line-height: 16px;
    margin: 0 18px 8px;
}

/* TIMELINE CARD */
.timeline-card{
    position: relative;
    padding: 0;
    text-align: center;
    margin: 12px 30% 24px -48px;

    background-color: white;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    transform: rotate(-2deg);
}

.timeline-card:after{
    content:'';
    display: block;
    width: 120px;
    height: 120px;
    position: absolute;
    top: 8px;
    right: 8px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url('../img/logo-anne-reitsema-fotografie.svg');
}

.timeline-card img{
    margin: 0;
    border: 0px solid #f6f6f6;
}

/* TIMELINE BREAK */
.timeline-break{
    padding: 18px 0;
    margin: 12px 40% 12px -18px;
    z-index: 500;
    background-color: white;
    border-top: 1px solid #bababa;
    border-bottom: 1px solid #bababa;
}
.timeline-break p{
    font-size: 14px;
    font-style: italic;
    line-height: 16px;
    margin: 0 18px 8px;
    font-family: 'Roboto Condensed', sans-serif;
}

/* TIMELINE QUOTE */
.timeline-quote{
    position: relative;
    padding: 36px 0;
    color: white;
    margin: 12px 36px 24px;
    border-radius: 16px;
    background: rgb(67,83,0); /* Old browsers */
    background: linear-gradient(135deg,  rgba(67,83,0,1) 0%,rgba(95,127,0,1) 100%); /* W3C */
}
.timeline-quote:before{
    content:'';
    display: block;
    width: 36px;
    height: 24px;
    position: absolute;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 18px solid #435300;
    top: 36px;
    left: -36px;
    background-color: white;
}
.timeline-quote h3{
    margin: 0 36px 8px;
}
.timeline-quote p{
    font-size: 36px;
    line-height: 36px;
    font-weight: 400;
    margin: 0 36px 8px;
}

.timeline-break , .timeline-quote , .timeline-card {
    > h2 {
        display: none;
    }
}
