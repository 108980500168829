.filter {
    // height: 100%;
    background-color: rgba(0,0,0,0.8);
    position: absolute;
    top: 0;
    overflow: hidden;
    right: -300px;
    z-index: 10;
    width: 240px;
    animation: all 1s;
    transition: all 0.5s ease;
    min-height: 100%;
    &.is-open {
        right:0;
        animation: all 1s;
    }
    @media screen and (max-width: 992px) {
        display: none;
    }
}

.filter-items ul{
    list-style-type: none;
    padding: 0;
    margin: 12px 0 12px;
}

.filter-items ul li {
     display: block;
     padding:0;
     margin:0;
}

.filter-items ul li a{
    position: relative;
    display: inline-block;
    // width: 50%;
    height: 44px;
    padding: 0 24px 0 52px;
    line-height: 44px;
    color: #9f9f9f;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        text-decoration: none;
    }
}

.filter-items ul li a span{
    position: absolute;
    top: 0;
    left: 0;
    height: 44px;
    line-height: 44px;
    font-size: 32px;
}

.filter-tag {
    &.is-checked{
        color: #fff;
    }
}

.filter-spacer {
    height: 1em;
}

